import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Heading from "@/components/Heading";
import Footer, { LinkItem } from "@/components/Footer";
import ProductOverview from "@/components/ProductOverview";
import { ProductFragment } from "@/fragments/product";
import { ShopQuery } from "../../graphql-types";

interface Props {
  data: ShopQuery;
}

const ShopPage = ({ data }: Props) => {
  const { headerLinks, footerLinks } = data.global || {};
  const productGroups = data.products.nodes.reduce<Record<string, ProductFragment[]>>((collections, product) => {
    const [collectionName] = (product.collections || []).filter(collection => {
      const isMacro = collection?.metafields?.find(metafield => {
        return metafield?.key === "is_macro" && metafield.value === "true";
      });

      return !isMacro;
    });

    if (!collectionName?.title) {
      return collections;
    }

    return {
      ...collections,
      [collectionName.title]: (collections[collectionName.title] || []).concat(product),
    };
  }, {});

  return (
    <Layout scroll="default" header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}>
      <Seo title="Shop" />
      <Layout.Section theme="white">
        <div className="container">
          {Object.entries(productGroups).map(([name, products], key) => {
            return (
              <div key={`${name}-${key}`} className="my-36 ">
                {/* @todo: remove replace when category is accurate. */}
                <Heading level={2}>{name.replace("SS23", "")}</Heading>
                <div className=" grid md:grid-cols-3 xl:grid-cols-4 gap-16">
                  {products.map(({ title, images, variants }) => {
                    const slug = variants?.[0]?.slug;
                    const isAvailable = variants?.some(variant => {
                      return variant?.inventoryQuantity && variant.inventoryQuantity > 0;
                    });

                    if (!slug || !title) {
                      return null;
                    }

                    return (
                      <div key={slug}>
                        <ProductOverview
                          title={title}
                          link={slug}
                          layout="slim"
                          image={<GatsbyImage image={images?.[1]?.gatsbyImageData} alt={title} />}
                          isOutOfStock={!isAvailable}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Layout.Section>
      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default ShopPage;

export const pageQuery = graphql`
  query Shop {
    products: allShopifyProduct(
      filter: { collections: { elemMatch: { title: { eq: "Spring Summer 2023 - Il Giardino di Venere" } } } }
    ) {
      nodes {
        ...Product
      }
    }
    global: dataJson {
      ...Global
    }
  }
`;
